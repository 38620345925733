:root {
  --color-orange: #ac5812;
  --color-orange-light: #fae5d3;
  --color-grey: #626262;
  --color-grey-mid: #aeaeae;
  --color-grey-light: #f2f2f2;
  --color-primary: #ac5812;
  --color-success: #063;
}

#button {
  background-color: #ac5812;
  border-color: #ac5812;
}

#recaptcha-image {
  position: fixed;
  right: 0;
  bottom: 0;
}

// /* Welcome screen ----------------------------------------------------------------------- */
// /* Layout ---------------------------------------------------------------- */
// /* Progressive enhancement for browsers that support CSS grid */
@supports (display: grid) {
  .wrapper-welcome {
    display: grid;
    grid-template-columns: 25% 1fr;
    height: 100vh;
  }

  .branding {
    display: flex;
    justify-content: flex-end;
  }
}

.bg-welcome {
  background: var(--color-grey-light);
  height: 100%;
}

.border-welcome {
  border-left: 2px solid var(--color-grey-mid);
}

/* Used on white boxes within main content area */
.card-welcome {
  background: white;
  border: 1px solid var(--color-grey-mid);
  padding: 1rem;
}

/*MPORT-5755 Cookie Banner Styles*/
.cookie-banner {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 1rem;
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 35rem;
  opacity: 0.85;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 1;
}

@media screen and (max-width: 812px) {
  .cookie-banner {
    width: 100%;
  }
}

// /* Project buttons and links --------------------------------------------- */

.btn.btn-link-project {
  color: var(--color-orange);
  text-decoration: underline;
  font-weight: 500;
}

.link-project {
  color: var(--color-orange);
}

.link-project:hover {
  background: var(--color-orange);
  color: #fff;
}

// //From common.scss prototype file

/* Flow utilities -------------------------------------------------------- */

.flow > * + * {
  margin-top: 1.5rem;
}

.flow-xs > * + * {
  margin-top: 0.5rem;
}

.flow-sm > * + * {
  margin-top: 1rem;
}

.flow-lg > * + * {
  margin-top: 2.5rem;
}

/* Global ---------------------------------------------------------------- */
/* Focus indication */
footer a {
  color: #ac5812;
}

a:focus,
.form-control:focus,
details summary:focus,
.btn:focus,
.btn-link-project:focus {
  outline: 2px solid black;
  outline-offset: 0.2rem;
}

/* Make images easier to work with */
img {
  max-width: 100%;
}

.modal.alert-modal {
  z-index: 1051;
}

.modal.common-modal {
  z-index: 1050;
}

/* Web Key screen ----------------------------------------------------------------------- */
.hdr-landing {
  font-size: 1.7rem;
  font-size: clamp(1.4rem, 2.5vw, 1.7rem);
  line-height: 2;
  font-weight: 600;
}

.card-signin {
  max-width: 26rem;
  margin: 0 auto;
  padding: 1rem;
}

.card-web-key {
  max-width: 18rem;
  margin: 1rem auto 0.5rem;
}

/* Header / Branding Area ------------------------------------------------ */
.branding-inner {
  max-width: 20rem;
  padding: 1rem;
  padding: clamp(0.5rem, 2.5vw, 3rem);
}

.branding-inner article {
  margin-top: 4rem;
}

/* Welcome Firstname Lastname */
.hdr-welcome {
  font-size: 1.3rem;
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  font-weight: 400;
  color: var(--color-grey);
  max-width: 18rem;
  word-wrap: break-word;
}

//   /* Claim number */
.text-claim {
  font-size: 1rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  font-weight: 500;
  margin-bottom: unset;
}

//   /* Who is Data iSight component */
.border-who {
  border-top: 2px solid var(--color-orange);
  padding-top: 0.5rem;
}

.btn-whois {
  display: none;
}

@media (max-width: 1100px) {
  /* Layout */
  .wrapper-welcome {
    display: block;
  }

  .border-welcome {
    border-left: unset;
  }

  .bg-welcome {
    height: unset;
  }

  /* Branding */
  .branding {
    display: unset;
    justify-content: unset;
  }

  .branding-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100vw;
  }

  .branding-inner article {
    display: none;
  }

  .who-text {
    display: none;
  }

  .btn-whois {
    display: block;
    margin-top: 1rem;
  }

  /* Upload component */
  .border-dropzone {
    border: unset;
  }

  .card-dropzone {
    display: none;
  }
}

@media (max-width: 700px) {
  .branding-inner {
    display: block;
    text-align: center;
    max-width: 100vw;
  }
}

.check-input {
  margin-top: 0.4rem !important;
}

.check-label {
  font-size: 0.75rem;
}

.date-input {
  width: 12rem !important;
}

.required-indicator {
  font-weight: bold;
  color: red;
}

/* Process component ------------------------------------------------ */
.hdr-process {
  font-size: 0.9rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.card-step {
  max-width: 12rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.card-step p {
  line-height: 1.6;
}

.bg-current {
  background: var(--color-orange-light);
}

//   /* Project buttons and links --------------------------------------------- */
.btn-project:disabled {
  background-color: #ccc;
  border-color: #ccc;
  color: #555;
}

.btn.btn-project {
  background-color: var(--color-orange);
  color: #fff;
  border-color: var(--color-orange);
  border-radius: 0;
}

.btn.btn-project:hover {
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  color: #fff;
  filter: brightness(110%);
}

.btn.btn-link-project.btn-link:hover {
  color: #212529;
  background: none;
}

input:disabled + #chooseFilesLabel {
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  color: #707070;
  cursor: auto;
}

.icon-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.PrivacyPolicyTerms {
  table,
  tr,
  td {
    border: 1px solid black;
  }

  td {
    padding: 0 10px;
  }

  ol.bold > li::marker {
    font-weight: bold;
  }

  .blue-highlight {
    font-weight: bold;
    color: #0070c0;
  }

  .centered-hed {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }
}

/* Stepper Component --------------------------------------------- */

.stepper-circle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  color: #FFF;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.stepper-circle.complete {
  background-color: #FFF;
  color: green;
}

.stepper-label {
  width: 100%;
  font-size: 1rem;
  color: #000;
}

.stepper-line {
  height: 0.25rem;
  width: 10rem;
  margin-bottom: 2rem;
}

.stepper-line.incomplete {
  background-color: var(--orange);
}

.stepper-line.complete {
  background-color: green;
}

@media (max-width: 1320px) {
  .stepper-line {
    width: 10rem;
  }
  .stepper-container {
    width: 12rem !important;
  }
}

@media (max-width: 1250px) {
  .stepper-line {
    width: 8rem;
  }
  .stepper-container {
    width: 10rem !important;
  }
}

@media (max-width: 1100px) {
  .stepper-line {
    width: 5rem;
  }
}

@media (max-width: 992px) {
  .stepper-line {
    display: none;
  }
  .stepper-container {
    width: 100% !important;
    margin-bottom: 2rem;
  }
}